@use "../abstracts" as *;

/* ########################################################################

 スタンダード用TOP

######################################################################## */
.standard {
  /* hero
********************************************** */
  .hero--cont {
    position: absolute;
    top: rem(264);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    @include mq(sp) {
      top: 50%;
      transform: translateY(-50%);
      width: 86%;
    }
    .ttl-06 {
      padding-top: rem(24);
    }
  }

  .hero--lead {
    @include lh(16, 32);
  }

  .hero_slides .slick-dots {
    position: absolute;
    bottom: rem(160);
    right: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include mq(med) {
      position: relative;
      bottom: rem(4);
    }
    button {
      display: none;
    }

    li {
      display: block;
      @include rect(8);
      background-color: #fff;
      opacity: 0.75;
      border-radius: 50%;
      cursor: pointer;
      transition: var(--transit-default);
      margin: rem(4);
      &.slick-active {
        background-color: var(--clr-wht);
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  /* home_newsline
  ********************************************** */
  .home_news--txt {
    height: 100%;
    width: rem(480);
    @include mq(sp) {
      width: 100%;
    }
  }
  .home_newsline {
    position: relative;
    .ttl-01 {
      margin-bottom: rem(8) !important;
      &::before {
        content: none;
      }
    }
    .subtitle {
      padding-left: rem(31);
      &::before {
        content: "";
        position: absolute;
        background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-star03.svg);
        width: rem(15);
        height: rem(15);
        background-size: contain;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .posts-news .post {
      padding-block: rem(40);
      margin-block: 0 !important;
      display: block;
      border-top: solid 2px var(--clr-mono-02) !important;
      &:last-child {
        border-bottom: solid 2px var(--clr-mono-02) !important;
      }
    }
    &::before {
      content: "";
      position: absolute;
      background-image: url(/wp/wp-content/themes/standard_sass/images/home/line-yama01.svg);
      background-size: cover;
      background-position: top right;
      width: 100%;
      height: vw(100);
      top: vw(-100);
      right: 0;
      background-repeat: no-repeat;
      @include mq(sp) {
        top: vw(-96.5);
      }
    }
  }
  .home_newsline .inner {
    gap: rem(40);
    @include mq(sp) {
      gap: sprem(10);
    }
  }
  .home_newsline--more {
    padding-top: rem(32);
    @include center-flex;
    color: var(--clr-primary-default);
    font-size: calc-fz(15);
    &:hover .btn-circle {
      background-color: var(--clr-primary-default);
      &::after {
        border-color: var(--clr-wht);
      }
    }
  }

  .posts-newsline {
    .post {
      margin-block: rem(-16);
      padding-block: rem(16);
      border: 0 !important;
      @include mq(sp, min) {
        width: vw(870);
      }
    }
    .cat_list {
      display: none;
    }
  }

  /* home_message
  ********************************************** */
  .home_message {
    position: relative;
    width: rem(1800);
    height: rem(1040);
    margin-inline: auto;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      left: 0;
      margin: 0 auto;
      background-image: url(/wp/wp-content/themes/standard_sass/images/home/message-bg.png);
      background-size: contain;
      background-repeat: no-repeat;
      @include mq(sp) {
        background-image: url(/wp/wp-content/themes/standard_sass/images/home/message-bg-sp.png);
      }
    }
    .lps_parts--column {
      @include mq(sp) {
        padding-top: sprem(80);
        padding-bottom: sprem(80);
      }
    }
    .ttl-03 {
      margin-bottom: rem(12) !important;
    }
    @include mq(sp) {
      width: 100%;
      height: 100%;
      &::after {
        background-size: cover;
        background-position: center;
      }
    }
    .btn a {
      background-color: var(--clr-primary-default);
      &:hover {
        opacity: 0.7;
      }
    }
  }
  /* home_winery
  ********************************************** */
  .home_winery {
    position: relative;
    padding-bottom: rem(200);
    @include mq(sp) {
      padding-bottom: sprem(108);
    }
    .ttl-02 {
      margin-bottom: rem(16) !important;
    }
    .ttl-03 {
      margin-bottom: rem(32) !important;
    }
    .lps_parts {
      margin-bottom: 0;
    }
    .lps_parts--img_text .inner:not(.flex-reverse) .img_text--txt {
      padding-right: 5.3333333333%;
      padding-left: 0 !important;
      @include mq(sp) {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: sprem(16);
      }
    }
    .lps_parts--img_text .inner.flex-reverse .img_text--txt {
      padding-left: 5.3333333333%;
      padding-right: 0 !important;
      @include mq(sp) {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: sprem(16);
      }
    }
    .btn {
      padding-top: rem(64);
    }
  }
  /* home_collection
  ********************************************** */
  .home_collection {
    position: relative;
    padding-bottom: rem(200);
    @include mq(sp) {
      padding-bottom: sprem(108);
    }
    .ttl-01 {
      margin-bottom: rem(32) !important;
    }
    .ttl-05 {
      display: block;
      padding-top: rem(8);
    }
    .lps_parts {
      margin-bottom: 0;
    }
    .text .ttl-01::before {
      content: none;
    }
    .lps_parts--img_text .inner .img_text--txt {
      padding-right: rem(80) !important;
      padding-left: rem(80) !important;
      @include mq(sp) {
        padding-right: sprem(24) !important;
        padding-left: sprem(24) !important;
      }
    }
    .home_btn {
      padding-top: rem(64);
    }
    .img img {
      height: 100%;
    }
    &::before {
      position: absolute;
      content: "";
      background-image: url(/wp/wp-content/themes/standard_sass/images/home/line-yama02.svg);
      background-size: cover;
      width: 100%;
      height: vw(100);
      top: vw(-100);
      @include mq(sp) {
        top: vw(-96);
      }
    }
  }
  /* home_stocklist
  ********************************************** */
  .home_stocklist {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      background-image: url(/wp/wp-content/themes/standard_sass/images/home/line-yama01.svg);
      background-size: cover;
      width: 100%;
      height: vw(100);
      top: vw(-100);
      background-repeat: no-repeat;
      @include mq(sp) {
        top: vw(-96.5);
      }
    }
  }
  /* 星の装飾
  ********************************************** */
  .home_star-top,
  .home_star-btm {
    position: absolute;
    @include mq(sp) {
      width: sprem(40);
      height: sprem(40);
    }
  }
  .home_star-top {
    top: rem(40);
    right: rem(80);
    @include mq(sp) {
      top: sprem(20);
      right: sprem(24);
    }
  }
  .home_star-btm {
    bottom: rem(160);
    left: rem(80);
    @include mq(sp) {
      bottom: sprem(40);
      left: sprem(24);
    }
  }
  .home_stocklist .home_star-btm {
    bottom: 0;
  }
}

/* home_cta
  ********************************************** */
.home .lps_parts--cta {
  padding-bottom: 0;
  margin-bottom: 0 !important;
  z-index: -1;
  .bg {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-bg.jpg) !important;
  }
}
