@use "../abstracts" as *;

/* section-contact
********************************************** */
.section-contact {
  font-family: var(--font-primary);
  .cta_tel a {
    padding: rem(24) rem(40);
    width: fit-content;
    @include auto-margin;
    @include mq(sp) {
      display: block;
      padding: sprem(16);
    }
    span {
      margin-top: 0;
    }
  }
  .nf-field-label .nf-label-span,
  .nf-field-label label {
    font-weight: 500 !important;
  }
}
