@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  position: relative;
  background-color: var(--clr-mono-06);
  &::before {
    position: absolute;
    content: "";
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/line-yama03.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: vw(100);
    top: vw(-100);
    @include mq(sp) {
      top: vw(-96.5);
    }
  }
}

/* footer_top
********************************************** */
/* ---fnav --- */
.fnav--menu {
  display: flex !important;
}
.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
  &:hover {
    opacity: 0.7;
  }
}
.fnav--menu .sub-menu {
  font-size: calc-fz(15);
  line-height: 1;
  opacity: 0.75;
}

/* ---sns_area --- */

.sns_area a {
  display: grid;
  place-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.shoplink {
  padding-left: rem(24);
}
.sns_area svg {
  fill: var(--clr-wht);
  @include rect(24);
  &.icon-x {
    @include rect(22);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(8) 0;
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}
.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.footer--cta {
  @include mq(sp, min, ps) {
    height: fit-content;
    width: fit-content;
    margin-inline: auto;
    .cta_contact {
      margin-bottom: 0;
      a {
        height: 100%;
      }
    }
  }
}

.footer--cta .cta_tel a {
  margin-inline: auto;
  width: 100%;
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}

/* footer-03
  *************************************************** */
.footer-03 {
  .inner {
    display: grid;
    @include mq(sp, min, ps) {
      grid-template-columns: 1fr rem(720);
      gap: 0 rem(56);
      grid-template-areas:
        "logo map"
        "cta_cmt  map"
        "address  map"
        "fnav  map"
        "copyright copyright";
    }
    @include mq(sp) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-areas:
        "logo"
        "cta_cmt"
        "address"
        "map"
        "fnav"
        "copyright";
    }
  }
  .footer_top {
    grid-area: logo;
  }
  .footer--logo {
    @include mq(sp) {
      text-align: center;
      margin-bottom: rem(24);
    }
  }
  .footer--cta {
    margin-bottom: rem(24);
    grid-area: cta;
    @include mq(sp) {
      align-items: center;
      justify-content: center;
    }
  }
  .fnav {
    grid-area: fnav;
    padding-left: rem(24);
  }
  .fnav--menu {
    @include mq(sp, min, ps) {
      margin-inline: -1em;
    }
  }
  .cta {
    grid-area: cta;
  }
  .copyright {
    padding-top: rem(56);
    grid-area: copyright;
    small {
      color: var(--clr-text-placeholder) !important;
    }
  }
  .sns_area {
    grid-area: sns_area;
    fill: var(--clr-wht);
  }
  .sns_area ul {
    justify-content: flex-end;
    @include mq(sp) {
      justify-content: flex-start;
    }
  }
  .footer--address {
    font-size: calc-fz(14);
    line-height: 1.7;
    grid-area: address;
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
    p,
    a {
      color: var(--clr-text-placeholder);
    }

    .name {
      font-size: calc-fz(20);
      color: var(--clr-text-light);
    }
  }
  .cta_comment {
    grid-area: cta_cmt;
    font-size: calc-fz(20);
    margin-top: 0;
    margin-bottom: rem(40);
    @include mq(sp) {
      margin-bottom: rem(24);
    }
  }
  .fmap {
    width: rem(680);
    overflow: hidden;
    border-radius: 40px 40px 40px 0px;
    border: solid 2px var(--clr-primary-default);
    grid-area: map;
    @include mq(sp, min, ps) {
      margin-left: auto;
    }
    @include mq(sp) {
      width: 100%;
      height: sprem(240);
      margin-bottom: sprem(24);
    }
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .fnav--menu a {
    display: block;
    padding: 0.5em 1em;
    &:hover {
      color: var(--clr-primary-default);
    }
    position: relative;
    padding: 0.5em 0.5em 0.5em 1.5em;
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      height: 2px;
      left: -0.5rem;
      background-color: var(--clr-primary-default);
    }
  }
}
.footer_star {
  position: absolute;
  top: rem(40);
  right: rem(80);
  @include mq(sp) {
    width: sprem(40);
    height: sprem(40);
    top: 0;
    right: sprem(24);
  }
}
