@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 96;
$header-hgt-sp: 64;
$header-hgt-fx: 80;
$list-dot: 10; // リストのドットサイズ
$list-gap: 16; // リストの余白
$list-start: $list-dot + $list-gap;
$check: 10; // チェックリストのチェックのサイズ
$check-dot: 24; // チェックリストのドットサイズ
$check-em: calc($check / $fz-pc) * 1em;
$check-dot-em: calc($check-dot / $fz-pc) * 1em;
$check-start-em: calc($check-em + $check-dot-em);
$flow-dot: 24; // 流れのドットサイズ
$flow-num-dot: 64; // 流れ（連番）のドットサイズ
$flow-gap: 32; // 流れの余白
$flow-bd: 3; // 流れのボーダーサイズ
$flow-bd-gap: 6; // 流れのボーダー余白
$flow-start: $flow-num-dot + $flow-gap;
$flow-dot-sp: 16; // 流れのドットサイズ（SP）
$flow-num-dot-sp: 40; // 流れ（連番）のドットサイズ（SP）
$flow-gap-sp: 16; // 流れの余白（SP）
$flow-bd-sp: 2; // 流れのボーダーサイズ（SP）
$flow-bd-gap-sp: 4; // 流れのボーダー余白
$flow-start-sp: $flow-num-dot-sp + $flow-gap-sp;

// カラー管理
$colors: (
  clr-primary-default: #a08a53,
  clr-primary-dark: #84744c,
  clr-primary-light: #af9c6f,

  clr-secondary-default: #03093b,
  clr-secondary-dark: #010310,
  clr-secondary-light: #0e1659,

  clr-bg-01: #f7f7f4,
  clr-bg-02: #f0efec,
  clr-bg-03: #f7f7f4,

  clr-sub-01: #a72119,
  clr-sub-02: #acaa64,
  clr-sub-03: #afa2b6,
  clr-sub-04: #c87767,
  clr-sub-05: #9a452e,
  clr-sub-06: #738992,

  clr-mono-01: #f2f2f2,
  clr-mono-02: #e1e1e1,
  clr-mono-03: #c7c7c7,
  clr-mono-04: #9fa0a0,
  clr-mono-05: #727171,
  clr-mono-06: #454545,

  clr-text-primary: #2d2f41,
  clr-text-secondary: #6e6f75,
  clr-text-placeholder: #cccccc,
  clr-text-light: #f7f7f7,

  clr-wht: #fff,
  clr-blk: #000,
);

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.075em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "Shuei KakuGo Gin L", sans-serif; // 本文。txt-md-jp
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", sans-serif; // ↑で足りないとき
  --font-jp: "Ishii Mincho Oldstyle Kana R", sans-serif; // title周りで使うfont。足りなければ増やす
  --font-en: "Boska", sans-serif;
  --font-en-sub: "EB Garamond", serif;
  //カラーを出力
  @each $key, $color in $colors {
    --#{$key}: #{$color};
  }

  // animation
  --transit-default: all 0.4s ease-out;
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
