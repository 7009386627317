@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-color: var(--clr-wht);
  z-index: z(header);
  transition: var(--transit-default);
  box-shadow: 0px 0px 1.5rem 0px rgba(0, 0, 0, 0.1);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(24);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(30);
    }
  }
  .sns_area {
    padding: 0 rem(40);
    svg {
      fill: var(--clr-text-primary);
    }
    a {
      width: fit-content;
      height: fit-content;
    }
    @include mq(sp) {
      ul {
        gap: 0 !important;
      }
      .instagram {
        display: none !important;
      }
    }
  }
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  @include mq(med) {
    width: auto;
    max-height: rem(40);
  }
  @include mq(sp) {
    width: sprem(300) !important;
    max-height: none;
  }
}

/* gnav
********************************************** */
.gnav,
.gnav--menu,
.gnav--menu li,
.gnav--link {
  font-family: "EB Garamond", "Shuei KakuGo Gin L";
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    overscroll-behavior-y: contain;
    background-color: var(--clr-bg-02);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .sns_area .shoplink {
    display: none;
  }
  @include mq(sp) {
    .sns_area svg {
      width: sprem(28);
      height: sprem(28);
    }
  }
}
.gnav--link {
  @include mq(med, min, ps) {
    @include center-flex;
  }
}

/* --- menu --- */
.gnav--menu {
  display: flex !important;
}
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--clr-primary-default);
    }
  }
}
.gnav--link {
  padding: 0 rem(16);
  @include mq(med) {
    display: block;
    padding: sprem(15);
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    float: inline-end;
    inline-size: sprem(7);
    margin-block: calc((1lh - sprem(7)) / 2);
    aspect-ratio: 1;
    border-top: 1px solid var(--clr-primary-default);
    border-right: 1px solid var(--clr-primary-default);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-wht);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
  a {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      width: rem(16);
      height: 2px;
      right: rem(-8);
      background-color: var(--clr-text-primary);
      @include mq(sp) {
        content: none;
      }
    }
  }
  &:hover ::after {
    background-color: var(--clr-primary-default);
  }
}
.head_sub_nav--wrap {
  z-index: 100;
  @include mq(med, min, ps) {
    position: absolute;
    top: 80%;
    left: rem(16);
    white-space: nowrap;
    transition: var(--transit-default);
    opacity: 0;
    pointer-events: none;
    border: solid 2px var(--clr-primary-default);
    border-radius: 0 rem(40) rem(40) rem(40);
    background-color: var(--clr-wht);
    padding: rem(32);
  }
  @include mq(med) {
    border-top: 1px dotted var(--clr-primary-default);
    display: none;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  display: flex;
  flex-direction: column;
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  display: block;
  position: relative;
  padding-right: rem(24);
  padding-left: 0;
  &::after {
    right: 0;
    background-color: var(--clr-text-placeholder) !important;
    transition: var(--transit-default);
  }
  @include mq(med) {
    padding: sprem(15);
    padding-left: sprem(30);
  }
  &:not(:last-child) {
    padding-bottom: rem(12);
  }
  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-primary-default) !important;
    }
  }

  span {
    padding-right: rem(24);
  }
}
.head_sub_nav a:hover::after {
  background-color: var(--clr-primary-default) !important;
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-12);
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--clr-primary-default);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    border-left: 1px solid #e7e5e4;
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
    &.active {
      background-color: var(--clr-bg-02);
    }
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-primary-default);
    }
  }
}
.gnav--cta {
  text-align: center;
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}

.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
  margin-inline: auto;
}
.gnav--tel .tel {
  font-size: calc-fz(20);
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: calc-fz(14);
}
.gnav--contact a {
  color: var(--clr-text-light);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
  }
}
.gnav--instagram {
  display: none !important;
  @include mq(sp) {
    display: flex !important;
  }
  span {
    font-size: calc-fz(13);
  }
  svg {
    fill: var(--clr-wht);
    width: rem(20);
    height: rem(20);
  }
}

/* header 02
********************************************** */
.header-02 {
  @include mq(med, min, ps) {
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-primary-default);
    }
  }
  .head_sub_nav--wrap {
    @include mq(sp) {
      padding-inline: sprem(14);
      background-color: var(--clr-wht);
      margin-bottom: sprem(24);
    }
  }
  .head_sub_nav li {
    @include mq(med, min, ps) {
      margin-block: rem(8);
    }
  }
  .head_sub_nav a {
    line-height: 1.5;
  }
  .gnav--tel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--clr-mono-01);
    a {
      background: none;
    }
  }
  .gnav--contact {
    height: 100%;
    width: rem(240);
    transition: var(--transit-default);
    @include mq(sp) {
      width: 100%;
      padding: sprem(8);
    }
    a::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
