@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(19) rem(74) rem(19) rem(56);
  width: fit-content;
  min-width: min(rem(280), 100%);
  color: var(--clr-wht);
  background-color: var(--clr-secondary-default);
  text-decoration: none;
  @include mq(sp) {
    min-width: 100%;
    text-align: center;
  }
  &:hover {
    background-color: var(--clr-primary-default);
  }
  &:where(:not([rel*="nofollow"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(40);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    rotate: 45deg;
    transition: var(--transit-default);
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
  svg {
    fill: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  &:hover {
    background-color: var(--clr-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}
/* --- btn-circle--- */
.btn-circle {
  position: relative;
  width: rem(56);
  height: rem(56);
  border: 2px solid var(--clr-primary-default);
  border-radius: 999px;
  transition: var(--transit-default);
  &::after {
    content: "";
    position: absolute;
    inset: 0 0.25rem 0 0;
    display: inline-block;
    margin: auto;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 2px solid var(--clr-primary-default);
    border-right: 2px solid var(--clr-primary-default);
    rotate: 45deg;
    transition: var(--transit-default);
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}
