@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-padding-top: rem($header-hgt-fx);
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
    scroll-padding-top: rem($header-hgt-sp);
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  padding-top: rem($header-hgt);
  word-break: break-word;
  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: calc-clamp($fz-pc, $wid-var);
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(med) {
    padding-top: sprem($header-hgt-sp);
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
ul,
ol {
  list-style: none;
}
small {
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}
a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}

/* 装飾
********************************************** */
.section-deco01 {
  position: absolute;
  top: rem(40);
  right: rem(80);
  width: rem(80);
  height: rem(80);
  @include mq(sp) {
    width: sprem(40);
    height: sprem(40);
    top: sprem(20);
    right: sprem(24);
  }
  &::before {
    position: absolute;
    content: "";
    background-size: contain;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
.section-deco02 {
  position: absolute;
  bottom: rem(160);
  left: rem(80);
  width: rem(80);
  height: rem(80);
  @include mq(sp) {
    width: sprem(40);
    height: sprem(40);
    bottom: sprem(40);
    left: sprem(24);
  }
  &::before {
    position: absolute;
    content: "";
    background-size: contain;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
.lps_sec:nth-child(odd) .section-deco01,
.lps_sec:nth-child(odd) .section-deco02 {
  &::before,
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/home/bg-star01.svg);
  }
}
.lps_sec:nth-child(even) .section-deco01,
.lps_sec:nth-child(even) .section-deco02 {
  &::before,
  &::before {
    background-image: url(/wp/wp-content/themes/standard_sass/images/home/bg-star02.svg);
  }
}
