@use "../abstracts" as *;

/* page_ttl
********************************************** */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: calc-fz(16);
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: rem(32);
  @include mq(sp) {
    padding-top: sprem(24);
  }
  .breadcrumbs {
    text-align: center;
  }
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: rem(380);
  @include mq(sp) {
    height: sprem(170);
  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .breadcrumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .breadcrumbs span span:not(:last-child)::after {
    background-color: var(--clr-wht);
  }
}

/* ttl
********************************************** */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  position: relative;
  letter-spacing: 0.1em;
  font-weight: 400;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
  span {
    line-height: inherit;
    letter-spacing: inherit;
  }
  &.font-en {
    font-weight: 500;
    letter-spacing: 0.05em;
  }
}
.ttl-01 {
  color: var(--clr-primary-default);
  font-size: rem(56);
  letter-spacing: 0.1em;
  line-height: 1.4;
  @include mq(sp) {
    font-size: sprem(36);
  }
  &.font-en {
    font-size: rem(80);
    line-height: 1.1;
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .subtitle {
    margin-top: rem(16);
    font-family: var(--font-primary);
    @include mq(sp) {
      margin-top: sprem(8);
    }
  }
  &.clr-wht .subtitle {
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: rem(15);
    height: rem(15);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-star03.svg);
    background-size: contain;
    top: rem(-15);
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
.ttl-02 {
  font-size: rem(48);
  line-height: 1.5;
  @include mq(sp) {
    font-size: sprem(30);
  }
  &.font-en {
    font-size: rem(56);
    line-height: 1.3;
    @include mq(sp) {
      font-size: sprem(30);
    }
  }
}
.ttl-03 {
  font-size: rem(40);
  line-height: 1.55;
  @include mq(sp) {
    font-size: sprem(24);
    line-height: 1.6;
  }
  &.font-en {
    font-size: rem(40);
    line-height: 1.4;
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.ttl-04 {
  font-size: rem(32);
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(20);
    line-height: 1.7;
  }
  &.font-en {
    font-size: rem(32);
    line-height: 1.5;

    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.ttl-05 {
  font-size: calc-fz(24);
  line-height: 1.65;
  font-weight: 500;
  @include mq(sp) {
    font-size: sprem(18);
    line-height: 1.7;
  }
  &.font-en {
    font-size: rem(24);
    line-height: 1.6;
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}
.ttl-06 {
  font-size: calc-fz(20);
  line-height: 1.7;
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: rem(20);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
}

/* subtitle
********************************************** */
.subtitle,
.subtitle-sm {
  letter-spacing: 0.075em !important;
}
.subtitle {
  display: block;
  color: var(--clr-primary-default);
  font-size: calc-fz(18);
  line-height: 1.6;
  font-weight: 600;
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: rem(18);
    font-family: "EB Garamond", serif;
    font-weight: 700;
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
}
.subtitle-sm {
  display: block;
  font-size: calc-fz(14);
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: rem(14);
    font-family: "EB Garamond", serif;
    font-weight: 600;
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.kazari-en {
  font-size: rem(152);
  font-family: "EB Garamond", serif;
  letter-spacing: 0.02em;
  @include mq(sp) {
    font-size: sprem(56);
    font-family: "Abril Fatface", serif;
  }
}
