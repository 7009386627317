@use "../abstracts" as *;

/* lps_parts--cta
********************************************** */
.lps_parts--cta {
  .ttl {
    margin-bottom: rem(40) !important;
  }
  .subtitle {
    margin-top: 0;
  }
  padding-top: rem(96);
  .cta_tel {
    a {
      color: var(--clr-text-light);
      @include mq(sp) {
        min-height: sprem(77);
      }
    }
    span {
      color: var(--clr-text-light);
    }
  }
  &:has(+ .home_footer_toplink) {
    margin-bottom: 0 !important;
  }
  .inner {
    background-color: var(--clr-bg-02);
    z-index: 1;
  }
  .cta_contact {
    @include mq(sp) {
      padding-top: calc(1em + rem(10));
    }
    a {
      background-color: var(--clr-secondary-default);
      &:hover {
        opacity: 0.7;
      }
      min-height: sprem(77);
    }
  }
  .bg {
    position: relative;
    inset-inline: 0;
    height: rem(800);
    background-position: center;
    background-size: cover;
    @include mq(sp) {
      height: sprem(640);
    }
  }
}
.lps_parts--cta_cont {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  @include mq(sp) {
    top: sprem(40);
  }
}
