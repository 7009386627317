@use "../abstracts" as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.related_page--list {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.related_page--list li {
  width: rem(460);
  height: rem(600);

  @include mq(sp) {
    height: sprem(240);
  }
}
.page .related_page--list li {
  position: relative;
  width: rem(460);
  height: rem(300);
  &:nth-of-type(1) {
    background-color: var(--clr-sub-06);
  }
  &:nth-of-type(2) {
    background-color: var(--clr-sub-03);
  }
  &:nth-of-type(3) {
    background-color: var(--clr-sub-04);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/relatedpage-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
  }
  @include mq(sp) {
    height: rem(200);
  }
}
.related_page--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: rem(48);
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
  @include mq(sp, min, ps) {
    height: 100%;
  }
  @include mq(sp) {
    padding: sprem(24) sprem(32) sprem(24) sprem(24);
    height: 100%;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    inset: 0;
    pointer-events: none;
    z-index: -1;
    transition: var(--transit-default);
  }
  p {
    z-index: 2;
    text-transform: capitalize;
  }
  span {
    z-index: 2;
  }
  &:hover {
    color: var(--clr-primary-default);
    &::before {
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  }
}
.related_page--list .font-jp {
  margin-bottom: 0.25em;
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.related_page--list .font-en {
  font-weight: 500;
  @include mq(sp) {
    font-size: sprem(32);
  }
}
.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
