@use "../abstracts" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: rem(920);
}
.hero--cont {
  position: absolute;
  top: 0;
}
.hero--catch {
}
.hero--lead {
}
.hero_slide {
  overflow: hidden;
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}

@include mq(sp) {
  .hero {
    height: sprem(540);
  }
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}

/* home_news
********************************************** */
.home_news {
  background-color: var(--clr-wht);
  border-bottom: 1px solid #eee;
}
.home_news .posts-news {
  flex: 1;
}
.posts-news--ttl {
  display: block;
  position: relative;
  margin-right: rem(40);
  padding: 0;
  line-height: 1.2;
  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: sprem(16);
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}
