@use "../abstracts" as *;

@include mq(sp, min, ps) {
  .pc-none {
    display: none;
  }
}

@include mq(sp) {
  .sp-none {
    display: none;
  }
}
